import React from "react"
import { navigate } from "@gatsbyjs/reach-router"

import Layout from "../components/Layout"
import { Link } from "gatsby"

export default function Error() {
  return (
    <Layout>
      <section className="section wrapper container min-h-[60vh] flex flex-col justify-center items-center">
        <div className="flex flex-col w-80 items-center justify-center h-full">
          <h2>404 Error</h2>
          <p className="my-4 text-center">
            This page was not found. Please check the url or{" "}
            <Link
              to="/find-a-builder/contact-us"
              className="underline hover:no-underline"
            >
              contact us directly
            </Link>
          </p>
          <button
            className="bg-primary text-white py-2 px-6 text-sm"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
      </section>
    </Layout>
  )
}
